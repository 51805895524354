import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
// jQuery global for plugins
window.$ = global.jQuery = require('jquery');
window.countDown = global.countDown = require('countdown-js');
window.Cookies = global.Cookies = require('js-cookie');

global.WS_URL = WS_URL;

window.bidUpdate = require('./modules/bidUpdate.module');
window.liveBidding = require('./modules/liveBidding.module');
import MicroModal from "micromodal"; // commonjs module
MicroModal.init();

require('./modules/nette.module').init();

// Cookies init
require('./modules/cookies.module').init();

// Languages init
require('./modules/languages.module').init();

// Scroll button init
// require('./modules/scroll.module').init();

// Discover more init
require('./modules/discover.module').init();

// Navigation init
require('./modules/navigation.module').init();

// Notifications init
require('./modules/notifications.module').init();

//Menu init
require('./modules/menu.module').init();

//Zoom Init
require('./modules/zoom.module').init();

//Carosel init
require('./modules/carousel.module').init();

//Chart init
require('./modules/charts.module').init();

require('./modules/forms.module').init();

//Popups init
require('./modules/popups.module').init();

require('./modules/ajaxDisableSubmit');

require('./modules/catalogueFilter.module').init();

require('./modules/fileName.module').init();

require('./modules/search.module').init();

require('./modules/dtzp.module').init();

require('../styles/common.scss');

// Item Detail Photo init
$(document).on('click', '.load-360', (e) => {
	e.preventDefault();
	let itemDetailPhoto = require('./modules/itemDetailPhoto.module');
	itemDetailPhoto.show();
	itemDetailPhoto.init();
});

$(function () {
	const time = document.querySelector('#local-time');
	if (!!time) {
		const trueDate = new Date(time.attributes['datetime'].value * 1000);
		time.title = trueDate.toString();
	}
	if (typeof startCountdown === 'function') startCountdown();
});

import './../../../Components/General/assets/scripts/events/forms';
import './../../../Components/General/assets/styles/app.scss';

import { createApp } from "vue";
import VueNativeSock from "vue-native-websocket-vue3";

import ImageUpload from '../../../Components/General/assets/components/image-upload.vue';
import BidCountdown from '../../../Components/General/assets/components/bid-countdown.vue';
import BidDeadline from '../../../Components/General/assets/components/bid-deadline.vue';
import TextAreaWithCountdown from '../../../Components/General/assets/components/textaAreaWithCountdown.vue';
import RangeSlider from "./components/rangeSlider";
import LotInfoCurrentValue from "./components/lotInfoCurrentValue.vue";

const app = createApp({
	components: {
		ImageUpload,
		BidCountdown,
		BidDeadline,
		TextAreaWithCountdown,
		RangeSlider,
		LotInfoCurrentValue,
	},
});

/**
 * Sentry also integrated in the ClientZone module. There is no shared module -> the code needs to be duplicated.
 * @see app/Modules/ClientZone/scripts/common.js
 */
Sentry.init({
	app,
	dsn: "https://f821e686f95649c8b4988e58da74c672@o252297.ingest.sentry.io/5889212",
	integrations: [
		// Tracking options are now deprecated https://docs.sentry.io/platforms/javascript/tracing/instrumentation/automatic-instrumentation/#tracepropagationtargets
		// Likely not very relevant until we want to integrate distributed tracing https://docs.sentry.io/concepts/key-terms/tracing/distributed-tracing/
		new Integrations.BrowserTracing({
			tracingOrigins: ["localhost", /^app/, /^demo/, /^staging/],
		}),
	],
	tracesSampleRate: 0.1, // 1% of transactions will be traced for Sentry Performance
});

if (typeof WS_URL !== 'undefined') {
	app.use(VueNativeSock, WS_URL, {format: "json", reconnection: true});
}
app.mount("#app");

if ($(".header-summers")) {
	$("#menuToggle").click(function () {
		$(this).toggleClass("open");
		$(".menu-summers").toggleClass("open");
	});
}

if ($(".header-curated")) {
	$("#menuToggle").click(function () {
		$(this).toggleClass("open");
		$(".menu-curated").toggleClass("open");
	});
}

if ($(".header-cma")) {
	$("#menuToggle").click(function () {
		$(this).toggleClass("open");
		$(".menu-cma").toggleClass("open");
	});
}

if ($(".header-depury")) {
	$("#menuDepuryToggle").click(function () {
		$(this).toggleClass("open");
		$("#depuryNav").toggleClass("open");
	});
}

if ($(".header-amati")) {
	$("#amatiToggle").click(function () {
		$(this).toggleClass("open");
		$(".menu-amati").toggleClass("open");
	});

	$(".menu-amati > li > span").click(function () {
		$(".menu-amati > li").removeClass("open");
		if($(this).hasClass("open")) {
			$(this).removeClass("open");
		} else {
			$(this).addClass("open");
			$(this).parent("li").addClass("open");
		}
	});
}

if ($(".hauser__header")) {
	$("#menuToggle").click(function () {
		$(this).toggleClass("open");
		$(".hauser__menu").toggleClass("open");
	});
}

if ($(".header-enkidus")) {
	$("#enkidusToggle").click(function () {
		$(this).addClass("open");
		$(".menu-enkidus").addClass("open");
		$(".menu-enkidus__overlay").fadeIn({duration:550});
	});

	$(".menu-enkidus__overlay").click(function () {
		$("#enkidusToggle").removeClass("open");
		$(".menu-enkidus").removeClass("open");
		$(".menu-enkidus__overlay").fadeOut({duration:550});
	});

	$(".enkidus-form input").keyup(function() {
		if($(this).val().length > 0) {
			$(".enkidus-form button").show();
		} else {
			$(".enkidus-form button").hide();
		}
	});
}

$(function () {
	if ($("#catInfoToggle")) {
		$("#catInfoToggle").click(function () {
			$(this).toggleClass("open");
			$("#catInfo").stop().slideToggle();
		});
	}
});

$(function () {
	document.querySelectorAll('[data-toggle="tooltip"]').forEach((el) => {
		const tooltipWrapper = document.createElement("div");
		tooltipWrapper.classList.add("lot__tooltip__wrapper");
		el.parentNode.insertBefore(tooltipWrapper, el);
		tooltipWrapper.appendChild(el);
		const tooltip = document.createElement("span");
		tooltip.classList.add('lot__tooltip');
		tooltip.innerText = el.getAttribute("data-title");
		el.append(tooltip);
	});
});
